import React, { useState, useEffect, useRef, useContext } from "react";
import Snackbar from "@mui/material/Snackbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";
import LinearProgress from "@mui/material/LinearProgress";
import ringtone from "../../assets/audio/unavailable.mp3";
import { MessageContext } from "../../context/MessageContext";
import { firestore } from "../../firebase";
import { sendMessage } from "../../firebase-config";

const ToastNotification = () => {
  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  const audioRef = useRef(null);
  const [msg, setMsg] = useState(null);
  const { message, setMessage, accepted, setAccepted, setMenuOpen, rejected, setRejected, rejectType, setRejectType, callSoundOn } = useContext(MessageContext);
  const user = localStorage.getItem("user");

  useEffect(() => {
    if (rejected) {
      // console.log("Rejected: ", rejected);
      if (rejectType == 'Rejected') {
        setMsg('Call Rejected');
      } else if (rejectType == 'Ignored') {
        setMsg('Call Not Answered');
      } else if (rejectType == 'Busy') {
        setMsg('User is on another call');
      } else if (rejectType == "User Unavailable") {
        setMsg('User Unavailable');
      } else if (rejectType == "No Answer") {
        setMsg('No Answer');
      } else if (rejectType == "Disable") {
        setMsg('Calling Feature is Disabled for this User')
      }
      handleClick();
    }
  }, [rejected]);

  const handleClick = () => {
    setOpen(true);
    setProgress(0);
    // message.seen = true;
    // setMessage(message);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    let timer;
    if (open) {
      timer = setInterval(() => {
        setProgress((prev) => {
          if (prev >= 100) {
            clearInterval(timer);
            setOpen(false);
            return 100;
          }
          return prev + 0.0333; // increment the progress by approximately 1/300 of 100 every 100ms
        });
      }, 100);

      // Play the sound continuously when the snackbar is shown
      if (callSoundOn && audioRef.current) {
        audioRef.current.loop = true; // Set the audio to loop
        audioRef.current
          .play()
          .catch((error) => console.error("Error playing audio:", error));
      }
    }

    return () => {
      clearInterval(timer);
      // Stop the audio when the snackbar is closed
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0; // Reset the audio to the beginning
      }
    };
  }, [open, callSoundOn]);


  return (
    <Stack spacing={2} sx={{ maxWidth: 600 }}>
      <audio ref={audioRef} src={ringtone} preload="auto" />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        message={
          <>
            {msg}
            {/* <LinearProgress
              variant="determinate"
              value={progress}
              sx={{ marginTop: 1 }}
            /> */}
          </>
        }
        action={
          <>
            {/* <Button color="secondary" size="small" onClick={handleAccept}>
              Accept
            </Button>
            <Button color="primary" size="small" onClick={handleReject}>
              Reject
            </Button> */}
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </Stack>
  );
};

export default ToastNotification;
