import Navbar from "./Navbar";
import logo from "../../../assets/draytalk-right.png";
import { Link } from "react-router-dom";
import React, { useState, useRef, useEffect, useMemo, useContext } from "react";
import LogoutButton from "../../logout/LogoutButton";
import axios from "axios";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { useMsal } from "@azure/msal-react";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import NotificationsPopup from "../Notification/NotificationPopup";
import { useAuth } from "../../../context/AuthContext";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import Login from "../../login/login";
import { actionCreators, AppState } from "../../../store";
import { getToken } from "../../../api";
import { Box, Spinner } from "@twilio-paste/core";
import { useClient } from "../../../context/ClientInfoContext";
import AppContainer from "../../AppContainer";
import { UserDetails } from "../../../axios/apis";
import { random } from "lodash";
import AppReadyContext from "../../../context/AppReadyContext";
import AccessContext from "../../../context/AccessContext";
import { OrgChart, TreeData } from "../../../axios/apis";
import UserProfileMenu from "../../UI/UserProfileMenu";
import CallingWindow from "../../UI/CallingWindow";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ToolTipHover from "../../UI/ToolTipHover";

const Header = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { login } = bindActionCreators(actionCreators, dispatch);
  const token = useSelector((state) => state.token);
  const username = localStorage.getItem("user") ?? " ";
  const password = localStorage.getItem("password") ?? " ";
  const { instance, accounts, inProgress } = useMsal();
  const userMenuRef = useRef(null);
  const userIconRef = useRef(null);
  const [activeUserMenu, setActiveUserMenu] = useState(false);
  const [accessToken, setAccessToken] = useState(null);
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState(null);
  const [appFlag, setAppFlag] = useState(null);
  const [userName, setUserName] = useState("");
  const [organization, setOrganization] = useState("");
  const { appReady, setAppReady } = useContext(AppReadyContext);
  const {
    userId,
    updateAccessToken,
    updateUserDetails,
    updateNavigation,
    updateUserId,
    updateUserRole,
    updateUserType,
    updateUserName,
    updateOrganization,
    updateRuleDetails
  } = useAuth();
  const { orgChart, setOrgChart, setSingleChart } = useContext(AccessContext);
  const { client, connectionState, currentConversation } = useClient();
  const label = useMemo(() => {
    switch (connectionState) {
      case "connected":
        return "Online";
      case "connecting":
        return "Connecting";
      default:
        return "Offline";
    }
  }, [connectionState]);
  let homePath = "/";
  if (localStorage.getItem("userType") == "DrayTalk-DrayTab") {
    homePath = "/draytab/chat";
  }

  const fetchData = async () => {
    try {
      if (orgChart.length == 0) {
        const res1 = await OrgChart();
        setOrgChart([res1.data]);
        // const res2 = await OrgChart();
        setSingleChart([res1.data]);
      }
    } catch (error) {
      // console.log(error);
    }
  };
  fetchData();

  useEffect(() => {
    function isWebView() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Check for iOS WebView
      if (
        (/iPhone|iPod|iPad/.test(userAgent) &&
          /AppleWebKit/.test(userAgent) &&
          !/Safari/.test(userAgent)) ||
        // Check for Android WebView
        userAgent.includes("wv") ||
        (userAgent.includes("Android") && userAgent.includes("Version/"))
      ) {
        return true;
      }
      return false;
    }
    console.log("webView: ", isWebView());
    localStorage.setItem("isWebView", isWebView());
  }, []);

  useEffect(() => {
    if (label == "Offline") {
      loginToken(localStorage.getItem("user"));
    }
  }, [label]);

  const setToken = (token) => {
    login(token);
    setLoading(false);
  };

  const loginToken = async (email) => {
    try {
      const token = await getToken(email.trim());
      if (token === "") {
        return "Received an empty token from backend.";
      }
      setToken(token);

      const randomValue = new Date().getTime();
      setAppReady(randomValue);
      return "";
    } catch (error) {
      console.error("Twilio Token: ", error);
      let message = "Unknown Error";
      if (error instanceof Error) {
        message = error.message;
      } else {
        message = String(error);
      }
      return message;
    }
  };

  if ((!token && !loading) || !username || !password) {
  }

  useEffect(() => {
    if (accounts.length > 0 && inProgress === "none") {
      instance
        .acquireTokenSilent({
          account: accounts[0],
          scopes: ["user.read"],
        })
        .then((response) => {
          setAccessToken(response.accessToken);
          localStorage.setItem("accessToken", response.accessToken);
          updateAccessToken(response.accessToken);
          fetchUserInfo(response.accessToken);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      fetchUserInfo(localStorage.getItem("accessToken"));
    }
  }, [accounts, inProgress, instance]);

  const fetchUserInfo = async () => {
    try {
      const response = await UserDetails();
      setUserEmail(response.data.userEmailId);
      setUserName(response.data.userName);
      setOrganization(response.data.organisationName);

      updateUserId(response.data.userEmailId);
      updateUserName(response.data.userName);
      updateUserRole(response.data.roles[0]);
      updateUserType(response.data.userType);
      updateOrganization(response.data.organisationName);
      loginToken(response.data.userEmailId);

      updateRuleDetails(response.data.isCallingDisabled, response.data.ruleSetId)
      // updateUserRole("Super Admin");
      const hasMissingRoles = response.data.roles[0];
      !hasMissingRoles ? <Navigate to="/unauthorized" /> : <Navigate to="/" />;
    } catch (error) {
      console.error("Error fetching user info: ", error);
      navigate("/unauthorized");
    }
  };

  const handleUserMenuClick = () => {
    setActiveUserMenu(!activeUserMenu);
  };

  const handleUserMenuItemClick = () => {
    setActiveUserMenu(false);
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const handleDocumentClick = (e) => {
    if (
      userMenuRef.current &&
      !userMenuRef.current.contains(e.target) &&
      userIconRef.current &&
      !userIconRef.current.contains(e.target)
    ) {
      setActiveUserMenu(false);
    }
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 300,
      fontSize: theme.typography.pxToRem(11),
      border: "1px solid #dadde9",
    },
  }));

  const convertLabel = (value) => {
    switch (value) {
      case "Online": return "Connected"
      case "Offline": return "Disconnected"
      case "Connecting": return "Connecting"
    }
  }


  return (
    <>
      <header>
        <div className="nav-area">
          <Link to={homePath}>
            <div className="logo">
              <img src={logo} height={40} alt="Logo" />
            </div>
          </Link>
          {/* <Navbar /> */}

          <div className="right-icons">
            {/* <div className="icon">📩</div> */}
            <CallingWindow />
            {/* <NotificationsPopup /> */}
            {/* <div className="icon">🔔</div> */}
            <ToolTipHover title={`Twilio Status : ${convertLabel(label)}`} position="bottom">
              <div className="">
                <FiberManualRecordIcon className={label.toLowerCase()} />
              </div>
            </ToolTipHover>
            {/* <div className="connection-status">
              <FiberManualRecordIcon className={label} />
              <span>{label}</span>
            </div> */}

            <UserProfileMenu
              name={userName}
              orgName={false}
              email={userEmail}
            />

            {/* <div className="user-profile">
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography color="inherit">
                      <span className="user-email">{userEmail}</span>
                      <br />
                      <span className="user-email">
                        {organization
                          ? `Org Name: ${organization}`
                          : "Org Name: --"}
                      </span>
                    </Typography>
                  </React.Fragment>
                }
              >
                <span>{userEmail && userEmail.slice(0, 10) + "..."}</span>
                <br />
                <span>{label}</span>
              </HtmlTooltip>
            </div> */}

            {/* <div
              ref={userIconRef}
              className="icon user-icon"
              onClick={handleUserMenuClick}
            >
              👤
            </div> */}

            <div
              ref={userMenuRef}
              className={`user-menu ${activeUserMenu ? "active" : ""}`}
              style={{
                display: activeUserMenu ? "block" : "none",
              }}
            >
              <div className="user-name">
                <span>{userName && userName}</span>
              </div>
              <div className="user-menu-item" onClick={handleUserMenuItemClick}>
                <button>User Settings</button>
                {/* <Link>User Settings</Link> */}
              </div>
              <div className="user-menu-item" onClick={handleUserMenuItemClick}>
                <LogoutButton />
              </div>
            </div>
          </div>
        </div>
      </header>

      {token && <AppContainer />}
    </>
  );
};

export default Header;
