import { Box, Input } from "@twilio-paste/core";
import { useTheme } from "@twilio-paste/theme";
import { Client } from "@twilio/conversations";

import ParticipantsView from "./ParticipantsView";
import Settings from "../settings/Settings";
import React, { useState, useEffect, useRef } from "react";
import { ReduxConversation } from "../../store/reducers/convoReducer";
import { ReduxParticipant } from "../../store/reducers/participantsReducer";
import { getTitle } from "../../api";
import { useContext } from "react";
import AccessContext from "../../context/AccessContext";
import UpdateConvoNameContext from "../../context/UpdateConvoName";
import SkletonUser from "../UI/SkletonUser";
import CallIcon from "@mui/icons-material/Call";
import { sendMessage } from "../../firebase-config";
import { MessageContext } from "../../context/MessageContext";
import { v4 as uuidv4 } from "uuid";

interface ConversationDetailsProps {
  convoSid: string;
  accessData: string;
  isChatAccess: boolean;
  participants: ReduxParticipant[];
  convo: ReduxConversation;
  client: Client;
  updateConvoName: (title: string) => void;
}

const ConversationDetails: React.FC<ConversationDetailsProps> = (
  props: ConversationDetailsProps
) => {
  const theme = useTheme();
  const [participantName, setParticipantName] = useState<string | null>(null);
  const [participantEmail, setParticipantEmail] = useState<string | null>(null);
  const [isManageParticipantOpen, setIsManageParticipantOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [participantloader, setParticipantLoader] = useState(false);
  const isCallingDisabled = localStorage.getItem("callingDisable")

  const [editedText, setEditedText] = useState(
    props.convo.friendlyName ?? props.convo.sid
  );
  const { convoName, setConvoName } = useContext(UpdateConvoNameContext);
  // const [editedText, setEditedText] = useState(
  //  ""
  // );
  const { orgChart } = useContext(AccessContext);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const {
    message,
    setMessage,
    accepted,
    setAccepted,
    setRejected,
    setIsCallingType,
  } = useContext(MessageContext);
  const handleEditClick = () => {
    setEditedText(props.convo.friendlyName ?? props.convo.sid);
    setIsEditing(true);
  };
  const handleInputChange = (convoName: string) => {
    setEditedText(convoName);
  };

  useEffect(() => {
    setEditedText(props.convo.friendlyName ?? props.convo.sid);
  }, [props.convoSid]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setIsEditing(false);
        if (editedText !== props.convo.friendlyName) {
          props.updateConvoName(editedText);
        }
      }
    };

    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        setIsEditing(false);
        if (editedText !== props.convo.friendlyName) {
          props.updateConvoName(editedText);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [editedText]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const names = await getTitle(props.convo.sid);

        if (names) {
          const participantNames = [];
          const participantEmails = [];
          if (names.length === 1) {
            participantNames.push(findUserByEmail(orgChart[0], names[0]));
            participantEmails.push(findUserEmail(orgChart[0], names[0]));
          } else if (names.length > 1) {
            for (let i = 0; i < Math.min(names.length, 3); i++) {
              participantNames.push(findUserByEmail(orgChart[0], names[i]));
            }
            for (let i = 0; i < names.length; i++) {
              participantEmails.push(findUserEmail(orgChart[0], names[i]));
            }

            if (names.length > 3) {
              const remainingCount = names.length - 3;
              participantNames.push(`and ${remainingCount} more`);
              // participantEmails.push(`and ${remainingCount} more`);
              // participantNames.push(props.convo.friendlyName)
            }
          } else {
            participantNames.push("No Participant")
            // participantNames.push(localStorage.getItem('userName'))
          }
          setParticipantName(participantNames.join(", "));
          setParticipantEmail(participantEmails.join(", "));
          setParticipantLoader(false);
        } else {
          console.log("Hit hit")

          setParticipantName("No Member")
        }
      } catch (error) {
        console.error("Unable to get participant name", error);
      }
    };
    setParticipantLoader(true);
    fetchData();
  }, [props.convo.sid, convoName]);


  const handleCallButtonClick = async () => {
    const sentBy = localStorage.getItem("user");
    const sentTo = participantEmail?.split(", ");
    const type = "Call";
    const room = uuidv4();
    // const room = localStorage.getItem("uuid");
    const names = participantName?.split(", ");
    const from = localStorage.getItem("userName");
    const comment = "some comment";
    const seen = false;
    const status = "initial";
    // Call the sendMessage function with the message parameters
    await sendMessage(
      {
        text: "Incoming Call",
        sentBy: sentBy,
        sentTo: sentTo,
        type: type,
        room: room,
        names: names,
        from: from,
        comment: comment,
        seen: seen,
        status: status,
      },
      sentTo,
      "Call"
    );
    // localStorage.setItem("roomId", room)
    setMessage({
      text: "",
      sentBy: sentBy,
      sentTo: sentTo,
      type: type,
      Room: room,
      names: names,
      from: from,
      comment: comment,
      seen: seen,
      status: status,
    });
    setAccepted(true);
    setIsCallingType("Calling");
    setRejected(false);
  };

  interface UserContact {
    id: string;
    userName: string;
    userEmailId: string;
    userJobRole: string;
    userDepartment: string;
    parentId: number;
  }

  interface TreeNode {
    department: string;
    userContactList: UserContact[];
    children: TreeNode[];
  }
  const findUserByEmail = (
    tree: TreeNode | null,
    email: string
  ): any | null => {
    if (!tree) return null;
    // Check the current level of the tree
    const user = tree.userContactList.find(
      (user) => user.userEmailId === email
    );
    if (user) {
      return user.userName; // Found the user at the current level
    }

    // If not found at the current level, recursively search in children
    for (const child of tree.children) {
      const result = findUserByEmail(child, email);
      if (result) {
        return result; // Found in the children
      }
    }

    return null; // Not found in the current branch
  };

  const findUserEmail = (tree: TreeNode | null, email: string): any | null => {
    if (!tree) return null;
    // Check the current level of the tree
    const user = tree.userContactList.find(
      (user) => user.userEmailId === email
    );
    if (user) {
      return user.userEmailId; // Found the user at the current level
    }

    // If not found at the current level, recursively search in children
    for (const child of tree.children) {
      const result = findUserEmail(child, email);
      if (result) {
        return result; // Found in the children
      }
    }

    return null; // Not found in the current branch
  };



  const stringToBool = (str: any) => str.toLowerCase() === "false";

  return (
    <Box
      style={{
        minHeight: 65,
        maxHeight: 65,
        paddingLeft: 16,
        paddingRight: 16,
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        borderBottomColor: theme.borderColors.colorBorderWeak,
      }}
    >
      <Box
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: participantloader ? "center" : "normal",
        }}
      >
        {participantloader ? (
          <SkletonUser />
        ) : (
          <>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
              color="colorText"
              fontFamily="fontFamilyText"
              fontSize="fontSize50"
              lineHeight="lineHeight80"
              fontWeight="fontWeightBold"
              maxHeight="100%"
              onClick={handleEditClick}
            >
              {/* {participantName} */}
              {/* {isEditing ? (
            <Input
              type="text"
              value={editedText}
              onChange={(e) => handleInputChange(e.target.value)}
              ref={inputRef}
            />
          ) : (
            <>{props.convo.friendlyName ?? props.convo.sid}</>
          )} */}
              {props.participants.length > 2 ? (
                isEditing ? (
                  <Input
                    type="text"
                    value={editedText}
                    onChange={(e) => handleInputChange(e.target.value)}
                    ref={inputRef}
                  />
                ) : (
                  <>{editedText}</>
                )
              ) : (
                <>{participantName}</>
              )}
            </Box>

            <Box
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              {localStorage.getItem("fcmToken") && stringToBool(isCallingDisabled) &&
                message &&
                message.type !== "Call" &&
                message.Type !== "call" && props.participants.length !== 1 && props.isChatAccess && (
                  <CallIcon
                    className="callIcon"
                    onClick={handleCallButtonClick}
                    htmlColor="black"
                  />
                )}
              {/* <CallIcon
                className="callIcon"
                onClick={handleCallButtonClick}
                htmlColor="black"
              /> */}

              <ParticipantsView
                participants={props.participants}
                onParticipantListOpen={() => setIsManageParticipantOpen(true)}
              />

              <Settings
                convo={props.convo}
                participants={props.participants}
                isManageParticipantOpen={isManageParticipantOpen}
                setIsManageParticipantOpen={setIsManageParticipantOpen}
                client={props.client}
                convoNameText={editedText}
                accessData={props.accessData}
              />
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default ConversationDetails;
