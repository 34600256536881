import React, { useContext, useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import SearchContext from "../../context/SearchContext";
import AccessContext from "../../context/AccessContext";
import { doesConversationExistWithEmail, readUserProfile } from "../../api";
import { doesConversationExistWithEmails } from "../../api";
import { useClient } from "../../context/ClientInfoContext";
import { actionCreators } from "../../store";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Conversation } from "@twilio/conversations";
import { unexpectedErrorNotification } from "../../helpers";
import { createConversation, createGroupConversation } from "../../api";
import ToolTipHover from "../UI/ToolTipHover";
import { Client, } from "@twilio/conversations";
import ChatIcon from '@mui/icons-material/Chat';

const Tree = ({ data = [] }) => {
  return (
    <div className="">
      <ul className="d-flex d-tree-container flex-column">
        {data.map((item, index) => (
          <>
            <TreeNode node={item} index={index} totalNodes={data.length} />
            {
              item.otherContactList && <OtherContactTree node={item.otherContactList} parentIndex={index} totalNodes={item.otherContactList.length} />

            }
          </>
        ))}
      </ul>
    </div>
  );
};

const TreeNode = ({ node, index, totalNodes }) => {

  const [childVisible, setChildVisible] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = useSelector((state) => state.token);
  const { updateCurrentConversation, addNotifications, updateParticipants } =
    bindActionCreators(actionCreators, dispatch);
  const { client, updateConnectionState, updateConversation } = useClient();
  const hasChild = node.children ? true : false;

  const { searchText, findList, searchEmail } = useContext(SearchContext);
  const { setSingleChart, setIsChatActive } = useContext(AccessContext);
  let homePath = "/";
  if (localStorage.getItem("userType") == "DrayTalk-DrayTab") {
    homePath = "/draytab/chat";
  }
  const includesSearchTerm = (text) =>
    text.toLowerCase() === searchText.toLowerCase();

  useEffect(() => {
    // const matchedTitles = searchTitle(searchText)
    findList.map((name) => {
      if (name === node.department) {
        setChildVisible(true);
      }
    });
  }, [searchText, searchEmail, findList, node]);

  useEffect(() => {
    if (node.treeLevel === 1) setChildVisible(true);
    // node.userContactList.map((el) => {
    //   if (el.parentId === 0) setChildVisible(true);
    // });
  }, []);

  const handleClick = (item) => {
    createNewConversation(item);
  };
  const createNewConversation = async (file) => {
    setIsChatActive(true)
    try {
      if (file.userEmailId == localStorage.getItem("user")) {
        unexpectedErrorNotification(
          "Cannot create conversation with self!",
          addNotifications
        );
        setIsChatActive(false)
        return;
      } else {
        const exists = await doesConversationExistWithEmails(
          client,
          [file]
        );

        if (exists) {
          updateConversation(exists);
          updateCurrentConversation(exists.sid);
          setIsChatActive(false)
          navigate(homePath);
        } else {
          const conv = await createConversation(
            file.userEmailId,
            updateParticipants,
            client,
            addNotifications
          );
          // const castedConversation = conv as Conversation;
          const castedConversation = conv instanceof Conversation ? conv : null;

          updateConversation(castedConversation);
          updateCurrentConversation(castedConversation.sid);
          setIsChatActive(false)
          navigate(homePath);
        }
      }
    } catch (error) {
      // Handle errors appropriately
      setIsChatActive(false)
      console.error("Error creating conversation:", error);
    }
  };

  const createGroup = async (file) => {
    setIsChatActive(true)
    try {
      const exists = await doesConversationExistWithEmails(client, file);
      if (exists) {
        updateConversation(exists);
        updateCurrentConversation(exists.sid);
        setIsChatActive(false)
        navigate(homePath);
      }
      else {
        const conv = await createGroupConversation(
          file,
          updateParticipants,
          client,
          addNotifications
        );
        // const castedConversation = conv as Conversation;
        const castedConversation = conv instanceof Conversation ? conv : null;

        updateConversation(castedConversation);
        updateCurrentConversation(castedConversation.sid);
        setIsChatActive(false)
        navigate(homePath);
      }
    } catch (error) {
      setIsChatActive(false)
      console.error("Error creating conversation:", error);
    }
  };

  const handleDepartmentClick = (departmentContactList) => {
    if (departmentContactList.length == 0) {
      return;
    } else if (departmentContactList.length == 1) {
      createGroup(departmentContactList);
    } else {
      createGroup(departmentContactList);
    }
  };


  const someFunction = async (email) => {
    // const client = new Client(token)
    // try {
    //   const user = await readUserProfile(email, client);
    //   if (user) {
    //     return true
    //   } else {
    //     return false
    //   }
    // } catch (error) {
    //   console.error("Error:", error);
    // }
    return true
  }


  return (
    <>
      <li className="d-tree-node" style={{ borderLeft: "2px solid black !important" }}>
        <div
          className="d-flex align-items-center"
        // onClick={() => setChildVisible(!childVisible)}
        >
          {hasChild && (
            <>
              {childVisible ? (
                <RemoveIcon
                  className="d-tree-icon"
                  onClick={() => setChildVisible(!childVisible)}
                />
              ) : (
                <AddIcon
                  className="d-tree-icon"
                  onClick={() => setChildVisible(!childVisible)}
                />
              )}
            </>
          )}

          <div>
            <h4 style={{ cursor: "pointer" }}
              className={`depart-head d-flex gap-3  ${includesSearchTerm(node.department) && "highlight"
                }`}
            >
              <div>
                {/* <ToolTipHover userExist={true} title={"Click to Start a Group Chat"} position={"right"}> */}
                {node.department}
                {/* </ToolTipHover> */}
              </div>
              <div className="contact-chat-icon" onClick={() => handleDepartmentClick(node.userContactList)}>
                <ToolTipHover userExist={true} title={`Click to Chat with ${node.department}`} position={"right"}>
                  <ChatIcon />
                </ToolTipHover>
              </div>
            </h4>
          </div>
        </div>

        {hasChild && childVisible && (
          <div className="d-tree-content">
            <div>
              <ul className="d-tree-container">
                {node.userContactList.map((item) => (
                  <li className={`d-tree-people`}>
                    <div className="d-people-node">
                      <span
                        onClick={() => handleClick(item)}
                        className={`people-head d-flex gap-3 ${item.userEmailId === searchEmail && "highlight"} ${!someFunction(item.userEmailId) && "disable-user"}`}
                      >

                        {/* <ToolTipHover userExist={someFunction(item.userEmailId)} title={`Click to Chat with ${item.userName}`} position={"right"}> */}
                        {item.userName}
                        {/* </ToolTipHover> */}
                        <div className="contact-chat-icon">
                          <ToolTipHover userExist={someFunction(item.userEmailId)} title={`Click to Chat with ${item.userName}`} position={"right"}>
                            <ChatIcon />
                          </ToolTipHover>
                        </div>
                      </span>
                      {/* <div className="status">
                                                    <FiberManualRecordIcon className={`${item.isOnline ? "online" : "offline"}`} />
                                                </div> */}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <ul className="d-flex d-tree-container flex-column">
              <Tree data={node.children} />
            </ul>
          </div>
        )}
      </li>

      {index !== totalNodes - 1 && <hr className="m-0" />
      }
    </>
  );
};


const OtherContactTree = ({ node, parentIndex, totalNodes }) => {

  const [childVisible, setChildVisible] = useState(false);
  const hasChild = node ? true : false;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = useSelector((state) => state.token);
  const { updateCurrentConversation, addNotifications, updateParticipants } =
    bindActionCreators(actionCreators, dispatch);
  const { client, updateConnectionState, updateConversation } = useClient();

  const { searchText, findList, searchEmail } = useContext(SearchContext);
  const { setSingleChart } = useContext(AccessContext);
  let homePath = "/";
  if (localStorage.getItem("userType") == "DrayTalk-DrayTab") {
    homePath = "/draytab/chat";
  }


  // const includesSearchTerm = (text) =>
  //   text.toLowerCase() === searchText.toLowerCase();

  useEffect(() => {
    // const matchedTitles = searchTitle(searchText)
    node.map((item) => {
      if (item.userName === searchText) {
        setChildVisible(true)
      }
    })
  }, [searchText]);

  // useEffect(() => {
  //   if (node.treeLevel === 1) setChildVisible(true);
  //   // node.userContactList.map((el) => {
  //   //   if (el.parentId === 0) setChildVisible(true);
  //   // });
  // }, []);

  const handleClick = (item) => {
    createNewConversation(item);
  };
  const createNewConversation = async (file) => {
    try {
      if (file.userEmailId == localStorage.getItem("user")) {
        unexpectedErrorNotification(
          "Cannot create conversation with self!",
          addNotifications
        );
        return;
      } else {
        const exists = await doesConversationExistWithEmails(
          client,
          [file]
        );

        if (exists) {
          updateConversation(exists);
          updateCurrentConversation(exists.sid);
          navigate(homePath);
        } else {
          const conv = await createConversation(
            file.userEmailId,
            updateParticipants,
            client,
            addNotifications
          );
          // const castedConversation = conv as Conversation;
          const castedConversation = conv instanceof Conversation ? conv : null;

          updateConversation(castedConversation);
          updateCurrentConversation(castedConversation.sid);
          navigate(homePath);
        }
      }
    } catch (error) {
      // Handle errors appropriately
      console.error("Error creating conversation:", error);
    }
  };

  // const createGroup = async (file) => {
  //   try {
  //     const exists = await doesConversationExistWithEmails(client, file);
  //     if (exists) {
  //       updateConversation(exists);
  //       updateCurrentConversation(exists.sid);
  //       navigate("/");
  //     }
  //     else {
  //       const conv = await createGroupConversation(
  //         file,
  //         updateParticipants,
  //         client,
  //         addNotifications
  //       );
  //       // const castedConversation = conv as Conversation;
  //       const castedConversation = conv instanceof Conversation ? conv : null;

  //       updateConversation(castedConversation);
  //       updateCurrentConversation(castedConversation.sid);
  //       navigate("/");
  //     }
  //   } catch (error) {
  //     console.error("Error creating conversation:", error);
  //   }
  // };

  // const handleDepartmentClick = (departmentContactList) => {
  //   if (departmentContactList.length == 0) {
  //     return;
  //   } else if (departmentContactList.length == 1) {
  //     createGroup(departmentContactList);
  //   } else {
  //     createGroup(departmentContactList);
  //   }
  // };


  const someFunction = async (email) => {
    const client = new Client(token)
    try {
      const user = await readUserProfile(email, client);
      if (user) {
        return true
      } else {
        return false
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  return (
    <>
      <li className="d-tree-node border-0">
        <div
          className="d-flex align-items-center"
        // onClick={() => setChildVisible(!childVisible)}
        >
          {hasChild && (
            <>
              {childVisible ? (
                <RemoveIcon
                  className="d-tree-icon"
                  onClick={() => setChildVisible(!childVisible)}
                />
              ) : (
                <AddIcon
                  className="d-tree-icon"
                  onClick={() => setChildVisible(!childVisible)}
                />
              )}
            </>
          )}

          <div>
            <h4 style={{ cursor: "pointer" }}
              className={`depart-head`}
            >
              <div>
                <ToolTipHover userExist={true} title={"Click to Start a Group Chat"} position={"right"}>
                  Other Contact List
                </ToolTipHover>
              </div>
            </h4>
          </div>
        </div>

        {hasChild && childVisible && (
          <div className="d-tree-content">
            <div>
              <ul className="d-tree-container">
                {node.map((item) => (
                  <li className={`d-tree-people`}>
                    <div className="d-people-node">
                      <span
                        onClick={() => handleClick(item)}
                        className={`people-head ${item.userEmailId === searchEmail && "highlight"} ${!someFunction(item.userEmailId) && "disable-user"}`}
                      >
                        <ToolTipHover userExist={someFunction(item.userEmailId)} title={`Click to Chat with ${item.userName}`} position={"right"}>
                          {item.userName}
                        </ToolTipHover>
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            {/* <ul className="d-flex d-tree-container flex-column">
              <Tree data={node.children} />
            </ul> */}
          </div>
        )}
      </li>

      {/* {parentIndex !== totalNodes - 1 && <hr className="m-0" />} */}
    </>
  )
}

export default Tree;
